/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Progress, PageHeader, Row, Col, Typography } from 'antd';
import Icon from '../Icon';
import './Header.less';

const HEADERS = {
  'service.new': {
    title: 'Nova Assinatura',
    description: 'Você está prestes a adquirir [SERVICE] da empresa [COMPANY]',
  },
  'customer.new': {
    title: 'Cliente',
    description: 'Você está prestes a adquirir [SERVICE] da empresa [COMPANY]',
  },
  'customer.address': {
    title: 'Cadastro do seu endereço',
    description: '',
  },
  'customer.attachment': {
    title: 'Documentos do cliente',
    description: 'Você está prestes a adquirir [SERVICE] da empresa [COMPANY]',
  },
  'contract.configs': {
    title: 'Dados da assinatura',
    description: 'Você está prestes a adquirir [SERVICE] da empresa [COMPANY]',
  },
  'contract.payment': {
    title: 'Dados de pagamento',
    description: 'Você está prestes a adquirir [SERVICE] da empresa [COMPANY]',
  },
  'contract.terms': {
    title: 'Termos de pagamento',
    description: 'Você está prestes a adquirir [SERVICE] da empresa [COMPANY]',
  },
};
const { Title, Text } = Typography;
function Header({ location, routes, serviceData: data }) {
  const history = useHistory();
  const inputRef = useRef();
  const [offsetTop, setOffsetTop] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [_, slug, linkId, group, action] = location.pathname.split('/');

  const base = 100 / (routes?.length || 1);
  const percent =
    routes.findIndex(({ path }) => {
      const [_Original, _slugNameOriginal, _linkIdOriginal, ...routeOriginal] =
        path?.split('/');
      const [_, _slugName, _linkId, ...route] = location.pathname?.split('/');
      return routeOriginal.join('/') === route.join('/');
    }) || 0;

  const onBack = () => history.goBack();

  const scrollHandler = () => {
    const { top } = inputRef.current.getBoundingClientRect();
    setOffsetTop(top);
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener('scroll', scrollHandler, true);
    return () => window.removeEventListener('scroll', scrollHandler, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data) return;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const message = HEADERS[`${group || 'service'}.${action || 'new'}`];

  return (
    <div ref={inputRef}>
      {offsetTop >= 0 && (
        <PageHeader
          className="header-fixed"
          key="pageheader"
          onBack={onBack}
          backIcon={percent === 0 ? null : <Icon type="arrow-left" />}
          title={
            <Row type="flex" justify="space-between" align="middle">
              <Col>
                <img
                  className="logo_avatar"
                  src={data?.company?.picture || '/logo192.png'}
                />
              </Col>
              <Col>
                <Row>
                  <Title className="title_header">{message?.title}</Title>
                </Row>
                <Row>
                  <Text type="secondary" className="text_header">
                    {(message?.description || '')
                      .replace('[SERVICE]', data?.name)
                      .replace('[COMPANY]', data?.company?.name)}
                  </Text>
                </Row>
              </Col>
            </Row>
          }
        >
          <Progress
            strokeColor={{
              '0%': '#95FC55',
              '100%': '#4CC399',
            }}
            trailColor="#f2f2f2"
            className="progress-level"
            percent={(percent + 1) * base}
            size="small"
            showInfo={false}
          />
        </PageHeader>
      )}
    </div>
  );
}

export default withRouter(Header);
